ROOT501.Accordion_A = Backbone.View.extend({
  
  events: {
    "click": "toggle"
  },
  
  initialize: function() {
    var self = this;
    _.bindAll(this, "toggle");
    this.target = this.$el.attr("href");
    this.duration = 500;
    this.easing = "easeInOutExpo";
    this.$target = $(this.target);
    
    if( this.$el.hasClass("is_active") ){
      
    } else {
      this.$target.slideUp(0);
    }
  },
  
  toggle: function(e) {
    this.$target.slideToggle(this.duration, this.easing);
    this.$el.toggleClass("is_active");
    return false;
  }
  
});
