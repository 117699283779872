$(function(){
  
  ROOT501.$body = $("body");
  ROOT501.$html_body = $("html, body");
  
  ROOT501.$body.addClass("is_js_on-1");
  
  $(".js_accordion_3").each(function(index, el){
    new ROOT501.Accordion_C({
      el: el,
      klass: "js_accordion_3"
    });
  });
  
  $(".js_accordion_2").each(function(index, el){
    new ROOT501.Accordion_B({
      el: el
    });
  });
  
  $(".js_accordion_1__toggle").each(function(index, el){
    new ROOT501.Accordion_A({
      el: el
    });
  });
  
  $(".carousel_1").nCarousel({
    duration: 1000
    ,easing: "easeOutExpo"
    ,surplus: 1
    ,interval: 6000
    ,draggable: true
  });
  
  new ROOT501.SmoothScroll();
  
  ROOT501.$body.addClass("is_js_on-2");
});