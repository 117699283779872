ROOT501.Accordion_B = Backbone.View.extend({
  
  initialize: function() {
    var self = this;
    _.bindAll(this, "open", "close");
    
    this.duration = 0;
    this.easing = "easeInOutExpo";
    
    this.$target = this.$(".js_accordion_b__target");
    this.$target.slideUp(0);
    
    this.$el.on({
      "mouseenter": this.open,
      "mouseleave": this.close
    });
  },
  
  open: function(){
    this.$target.stop(true,false).slideDown(this.duration, this.easing);
    this.$el.addClass("is_active");
  },
  
  close: function(){
    this.$target.stop(true,false).slideUp(this.duration, this.easing);
    this.$el.addClass("is_active");
  }
  
});
