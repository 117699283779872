/*
## SmoothScroll
*/

ROOT501.SmoothScroll = function(opts) {
  opts = _.extend({
    target: ".js_smooth-scroll_1"
  }, opts);
  
  $(opts.target).click(function() {
    var href = $(this).attr("href");
    href = href.substring(href.indexOf("#"), href.length);
    var $href = $(href);
    console.log($href.length);
    if( 0 < $href.length ){
      ROOT501.$html_body.stop(true,false).animate({
        scrollTop: $href.offset().top
      }, 1500, "easeOutExpo");
      return false;
    }
  });
}
